import Image from 'next/image';
import * as React from 'react';
import { ShirtType } from '~source/core/models/components/atoms/size';
import useAjaxFont from '~source/ui/components/organisms/shirt-configurator/hooks/use-ajax-font';
import useShirtCanvasDetails from '~source/ui/components/organisms/shirt-configurator/hooks/use-shirt-canvas-details';
import useDebouncedValue from '~source/ui/hooks/helper/useDebouncedValue/useDebouncedValue';
import { useTranslate } from '~source/ui/hooks/helper/useTranslate/useTranslate';
import isSpecialLetter from '~source/ui/utils/checks/is-special-letter';
import debounce from '~source/ui/utils/debounce';
import { cx } from '~source/ui/utils/join-classnames';
import $ from './shirt-canvas-2d.module.scss';

type Props = {
  printingName: string;
  printingNumber: string;
  frontImage: string;
  backImage: string;
  shirtType: ShirtType;
  isInShirtCarousel?: boolean;
  isFlipButtonVisible?: boolean;
  initialSide?: 'front' | 'back';
  scaleFactor?: number;
  onLoaded?: () => void;
  onError?: () => void;
};

function calculatePositionYOnCanvas(data: {
  printing: string;
  index: number;
  posY: number;
  extraHeight: number;
}): number {
  const { printing, index, posY, extraHeight } = data;
  const letter = printing[index];
  const hasSpecialLetter = isSpecialLetter(letter);
  // The one is to get the home font perfectly aligned, after shirt release the AJAX team will provide us with equally high SVG's so we can remove these non-futureproof implementations
  // Because the accent in ç is at the bottom, the position of the letter needs to be set differently
  if (hasSpecialLetter && letter === 'ç') return posY;
  if (hasSpecialLetter) return posY - extraHeight - 1;
  return posY;
}

function calculateDimensions(data: {
  letter: string;
  img: HTMLImageElement;
  height: number;
  accentRatio: number;
}) {
  const { letter, img, height, accentRatio } = data;
  const modifiedHeight = !isSpecialLetter(letter)
    ? height
    : height * accentRatio;
  const ratio = modifiedHeight / img.height;
  return {
    height: modifiedHeight,
    width: Math.ceil(img.width * ratio),
  };
}

const ShirtCanvas2D: React.FC<Props> = ({
  printingName,
  printingNumber,
  shirtType,
  frontImage,
  backImage,
  isInShirtCarousel = false,
  isFlipButtonVisible = isInShirtCarousel,
  initialSide = 'front',
  scaleFactor = 1,
  onLoaded,
  onError,
}) => {
  const t = useTranslate();

  const imageRef = React.useRef<HTMLImageElement>(null);
  const canvasWrapperRef = React.useRef<HTMLDivElement>(null);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  const [isCanvasLoaded, setIsCanvasLoaded] = React.useState(false);

  const { outputArray: printingNameImages } = useAjaxFont(
    printingName,
    shirtType,
  );
  const { outputArray: printingNumberImages } = useAjaxFont(
    printingNumber,
    shirtType,
  );
  const debouncedPrintingNameImages = useDebouncedValue(
    printingNameImages,
    300,
  );
  const debouncedPrintintNameImages = useDebouncedValue(
    printingNumberImages,
    300,
  );
  const [isFrontSide, setIsFrontSide] = React.useState(initialSide === 'front');
  const [originalWidth, setOriginalWidth] = React.useState(0);
  const [originalHeight, setOriginalHeight] = React.useState(0);
  const style = { width: originalWidth, height: originalHeight };
  const { getShirtDetail, spaceWidth } = useShirtCanvasDetails({
    originalHeight,
    shirtType,
  });

  const flip = React.useCallback(() => {
    setIsFrontSide(!isFrontSide);
    if (imageRef.current) {
      // Do a little trick to retrigger the animation
      imageRef.current.classList.toggle($.rightToLeft);
      // eslint-disable-next-line no-unused-expressions
      imageRef.current.offsetWidth;
      imageRef.current.classList.toggle($.rightToLeft);
    }
  }, [isFrontSide]);

  const drawImageString = React.useCallback(
    ({
      ctx,
      array,
      height: nameHeight,
      posY,
      padding,
      printing,
    }: {
      ctx: CanvasRenderingContext2D;
      printing: string;
      array: (HTMLImageElement | undefined)[];
      height: number;
      cropFactor: number;
      posY: number;
      padding: number;
    }) => {
      if (array.length <= 0) return;

      const accentRatio = getShirtDetail('specialCharacterRatio');

      const nameLength = array.reduce((acc, img, index) => {
        if (!img) return acc + spaceWidth;
        // If the letter contains an accent, the letter needs to be bigger than the rest
        const { width } = calculateDimensions({
          letter: printing[index],
          img,
          height: nameHeight,
          accentRatio,
        });
        return acc + width + (index !== 0 ? padding : 0);
      }, 0);
      let positionX = originalWidth / 2 - nameLength / 2;

      // TODO: fix cancellation of previous promise after clearing canvas if new array value
      array.forEach((img, index) => {
        if (!img) {
          positionX += spaceWidth;
          return;
        }
        // If the letter contains an accent, the letter needs to be bigger than the rest
        const { height, width } = calculateDimensions({
          letter: printing[index],
          img,
          height: nameHeight,
          accentRatio,
        });
        // Firefox needs width/height attributes on the root svg element to make this work
        const positionY = calculatePositionYOnCanvas({
          printing,
          index,
          posY,
          extraHeight: nameHeight * accentRatio - nameHeight,
        });

        ctx.drawImage(
          img,
          Math.ceil(positionX),
          Math.ceil(positionY),
          width,
          Math.ceil(height),
        );
        // Add an additional padding when the img is not the last img in the array
        positionX =
          positionX + width + (index !== array.length - 1 ? padding : 0);
      });
    },
    [getShirtDetail, originalWidth, spaceWidth],
  );

  const drawName = React.useCallback(
    (ctx: CanvasRenderingContext2D) => {
      if (printingNameImages.length <= 0) return;
      const height = getShirtDetail('nameHeight') * scaleFactor;
      const cropFactor = 1;
      const posY = getShirtDetail('namePosY') * scaleFactor;
      const padding = height * getShirtDetail('paddingCharacterRatio');

      drawImageString({
        ctx,
        array: printingNameImages,
        height,
        cropFactor,
        posY,
        padding,
        printing: printingName,
      });
    },
    [
      printingNameImages,
      getShirtDetail,
      scaleFactor,
      drawImageString,
      printingName,
    ],
  );

  const drawNumber = React.useCallback(
    (ctx: CanvasRenderingContext2D) => {
      if (printingNumberImages.length <= 0) return;
      const height = getShirtDetail('numberHeight') * scaleFactor;
      const cropFactor = 4;
      const posY = getShirtDetail('numberPosYRatio');
      const padding = height * getShirtDetail('paddingNumberRatio');

      drawImageString({
        ctx,
        array: printingNumberImages,
        height,
        cropFactor,
        posY,
        padding,
        printing: printingNumber,
      });
    },
    [
      printingNumberImages,
      getShirtDetail,
      scaleFactor,
      drawImageString,
      printingNumber,
    ],
  );

  const scale = React.useCallback(
    (context: CanvasRenderingContext2D) => {
      const pixelRatio =
        typeof window !== 'undefined' ? window.devicePixelRatio : 1;
      const dw = Math.floor(pixelRatio * originalWidth);
      const dh = Math.floor(pixelRatio * originalHeight);
      context.canvas.width = dw;
      context.canvas.height = dh;

      context.scale(pixelRatio, pixelRatio);
    },
    [originalWidth, originalHeight],
  );

  const draw = React.useCallback(() => {
    const container = canvasWrapperRef.current;
    const rect = container?.getBoundingClientRect();

    if (rect) {
      setOriginalWidth(rect.width);
      setOriginalHeight(rect.height);
    }
    const canvas = canvasRef.current;
    if (!canvas) return;
    const context = canvas.getContext('2d');

    if (context) {
      context.clearRect(0, 0, canvas.width, canvas.height);
      // draw canvas container
      scale(context);
      // draw elements
      drawName(context);
      drawNumber(context);

      onLoaded?.();
    }
  }, [drawName, drawNumber, scale, onLoaded]);
  const drawRef = React.useRef(draw);

  React.useEffect(() => {
    drawRef.current = draw;
  }, [draw]);

  const currentWidth = React.useRef<number | null>(null);
  React.useEffect(() => {
    if (typeof window !== 'undefined') currentWidth.current = window.innerWidth;

    const handler = () => {
      if (currentWidth.current === window.innerWidth) return;
      currentWidth.current = window.innerWidth;
      drawRef.current();
    };
    window.addEventListener('resize', debounce(handler, 500));
    return () => window.removeEventListener('resize', handler);
  }, []);

  React.useEffect(() => {
    draw();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPrintingNameImages, debouncedPrintintNameImages]);

  const handleCanvasLoaded = () => {
    setIsCanvasLoaded(true);
    onLoaded?.();
  };

  return (
    <div className={$.wrapper}>
      {isInShirtCarousel && isFlipButtonVisible && (
        <button type="button" className={$.buttonFlip} onClick={flip}>
          <img
            src="/shop/images/icons/ic-flip.svg"
            width="24"
            height="24"
            alt="Flip"
          />
          {isFrontSide ? t('SHIRT_BACK_SIDE') : t('SHIRT_FRONT_SIDE')}
        </button>
      )}
      <div className={$.container}>
        <div className={$.imageWrapper}>
          {isInShirtCarousel && (
            <Image
              src={frontImage}
              alt="shirt front"
              className={cx(isFrontSide ? $.rightToLeft : $.leftToRight)}
              fill
              style={{ objectFit: 'contain' }}
              sizes="(min-width: 56rem) 560px, 294px"
              onLoadingComplete={handleCanvasLoaded}
              onError={onError}
            />
          )}
          <Image
            src={backImage}
            alt="shirt back"
            className={cx(
              isInShirtCarousel && isFrontSide ? $.leftToRight : undefined,
              isInShirtCarousel && !isFrontSide ? $.rightToLeft : undefined,
            )}
            fill
            style={{ objectFit: 'contain' }}
            sizes="(min-width: 56rem) 560px, 294px"
            onLoadingComplete={handleCanvasLoaded}
            onError={onError}
          />

          {(!isCanvasLoaded || !shirtType) && (
            <div className={$.fallback}>
              <span className={$.blink} />
            </div>
          )}
        </div>
        <div className={$.canvasWrapper} ref={canvasWrapperRef}>
          <canvas
            ref={canvasRef}
            style={style}
            className={cx(
              $.canvas,
              isInShirtCarousel && isFrontSide ? $.leftToRight : undefined,
              isInShirtCarousel && !isFrontSide ? $.rightToLeft : undefined,
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default ShirtCanvas2D;
