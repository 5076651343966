import { ShirtType } from '~source/core/models/components/atoms/size';
import { ShirtConfiguration } from '~source/core/models/components/molecules/shirt-configuration';

export default function getConfiguratorImagesUrl({
  shirtType,
  hasAdditionalLogo,
  shirtConfigurations,
}: {
  shirtType: ShirtType | null;
  hasAdditionalLogo: boolean;
  shirtConfigurations: ShirtConfiguration[] | undefined;
}) {
  const shirt = shirtConfigurations?.find(
    (shirtConfiguration: ShirtConfiguration) =>
      shirtConfiguration.shirtType === shirtType,
  );
  const shirtBackImage = hasAdditionalLogo
    ? shirt?.backImageWithSponsors?.url
    : shirt?.backImage?.url;
  return {
    back: shirtBackImage ?? '/shop/images/fallback-shirt-back-2223.webp',
    front:
      shirt?.frontImage?.url ?? '/shop/images/fallback-shirt-front-2223.webp',
  };
}
