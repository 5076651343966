import * as React from 'react';
import loadImage from '~source/ui/components/organisms/shirt-configurator/utils/load-image';

type ImageCache =
  | {
      type: 'resolved';
      images: HTMLImageElement[];
    }
  | {
      type: 'pending';
      promise: Promise<HTMLImageElement[]>;
    };

const cacheMap = new Map<string, ImageCache>();

async function prepareImagePromises(imagePaths: string[]) {
  if (typeof window === 'undefined') return [];
  return Promise.all(imagePaths.map((imagePath) => loadImage(imagePath)));
}

export default function useHTMLImagesCache(key: string, imagePaths: string[]) {
  const [images, setImages] = React.useState<HTMLImageElement[]>([]);

  React.useEffect(() => {
    let cacheState = cacheMap.get(key);
    if (cacheState === undefined) {
      cacheState = {
        type: 'pending',
        promise: prepareImagePromises(imagePaths),
      };
    }
    if (cacheState.type === 'resolved') {
      setImages(cacheState.images);
    }
    if (cacheState.type === 'pending') {
      cacheState.promise.then((result) => {
        setImages(result);
        cacheMap.set(key, { type: 'resolved', images: result });
      });
    }
  }, [key, imagePaths]);

  return images;
}
