import { ShirtType } from '~source/core/models/components/atoms/size';

// Got this beauty from https://stackoverflow.com/questions/42118296/dynamically-import-images-from-a-directory-using-webpack
// eslint-disable-next-line no-undef
export default function transformImportedAjaxFont(
  // eslint-disable-next-line no-undef
  r: __WebpackModuleApi.RequireContext,
  type: ShirtType,
) {
  const images: string[] = [];
  const svgFiles = r.keys();

  if (svgFiles) {
    svgFiles.forEach((item) => {
      const character = item
        .replace('./', '')
        .replace(`public/images/icons/ajax-font/${type}/`, '')
        .replace('.svg', '');
      images.push(`/shop/images/icons/ajax-font/${type}/${character}.svg`);
    });
  }
  return images;
}
