import * as React from 'react';
import { SHIRT_TYPES } from '~source/constants';
import type { ShirtType } from '~source/core/models/components/atoms/size';
import getAjaxFontSRC from '~source/ui/components/organisms/shirt-configurator/utils/get-ajax-font-src';
import useHTMLImagesCache from './use-html-images';

const homeImagePaths = getAjaxFontSRC(
  require.context(
    `/public/images/icons/ajax-font/2324_home`,
    false,
    /\.(svg)$/,
  ),
  SHIRT_TYPES.home,
);

const homeNewImagePaths = getAjaxFontSRC(
  require.context(
    `/public/images/icons/ajax-font/2425_home`,
    false,
    /\.(svg)$/,
  ),
  SHIRT_TYPES.homeNew,
);

const homeWomenImagePaths = getAjaxFontSRC(
  require.context(
    `/public/images/icons/ajax-font/2425_home_vrouwen`,
    false,
    /\.(svg)$/,
  ),
  SHIRT_TYPES.homeWomen,
);

const awayImagePaths = getAjaxFontSRC(
  require.context(
    `/public/images/icons/ajax-font/2324_away`,
    false,
    /\.(svg)$/,
  ),
  SHIRT_TYPES.away,
);

const awayNewImagePaths = getAjaxFontSRC(
  require.context(
    `/public/images/icons/ajax-font/2425_away`,
    false,
    /\.(svg)$/,
  ),
  SHIRT_TYPES.awayNew,
);

const thirdImagePaths = getAjaxFontSRC(
  require.context(`/public/images/icons/ajax-font/2324_3rd`, false, /\.(svg)$/),
  SHIRT_TYPES.third,
);

const thirdNewImagePaths = getAjaxFontSRC(
  require.context(`/public/images/icons/ajax-font/2425_3rd`, false, /\.(svg)$/),
  SHIRT_TYPES.thirdNew,
);

const keeperImagePaths = getAjaxFontSRC(
  require.context(
    `/public/images/icons/ajax-font/2324_keeper`,
    false,
    /\.(svg)$/,
  ),
  SHIRT_TYPES.keeper,
);

const keeperNewImagePaths = getAjaxFontSRC(
  require.context(
    `/public/images/icons/ajax-font/2425_keeper`,
    false,
    /\.(svg)$/,
  ),
  SHIRT_TYPES.keeperNew,
);

export default function useAjaxFont(input: string, type: ShirtType | null) {
  const homeHTMLImages = useHTMLImagesCache(SHIRT_TYPES.home, homeImagePaths);
  const homeNewHTMLImages = useHTMLImagesCache(
    SHIRT_TYPES.homeNew,
    homeNewImagePaths,
  );
  const homeWomenHTMLImages = useHTMLImagesCache(
    SHIRT_TYPES.homeWomen,
    homeWomenImagePaths,
  );
  const awayHTMLImages = useHTMLImagesCache(SHIRT_TYPES.away, awayImagePaths);
  const awayNewHTMLImages = useHTMLImagesCache(
    SHIRT_TYPES.awayNew,
    awayNewImagePaths,
  );
  const thirdHTMLImages = useHTMLImagesCache(
    SHIRT_TYPES.third,
    thirdImagePaths,
  );
  const thirdNewHTMLImages = useHTMLImagesCache(
    SHIRT_TYPES.thirdNew,
    thirdNewImagePaths,
  );
  const keeperHTMLImages = useHTMLImagesCache(
    SHIRT_TYPES.keeper,
    keeperImagePaths,
  );
  const keeperNewHTMLImages = useHTMLImagesCache(
    SHIRT_TYPES.keeperNew,
    keeperNewImagePaths,
  );

  const [inputArray, setInputArray] = React.useState<string[]>([]);
  const [outputArray, setOutputArray] = React.useState<HTMLImageElement[]>([]);

  const images = React.useMemo(() => {
    switch (type) {
      case SHIRT_TYPES.away:
        return awayHTMLImages;
      case SHIRT_TYPES.awayNew:
        return awayNewHTMLImages;
      case SHIRT_TYPES.third:
        return thirdHTMLImages;
      case SHIRT_TYPES.thirdNew:
        return thirdNewHTMLImages;
      case SHIRT_TYPES.keeper:
        return keeperHTMLImages;
      case SHIRT_TYPES.keeperNew:
        return keeperNewHTMLImages;
      case SHIRT_TYPES.homeWomen:
        return homeWomenHTMLImages;
      case SHIRT_TYPES.homeNew:
        return homeNewHTMLImages;
      case SHIRT_TYPES.home:
      default:
        return homeHTMLImages;
    }
  }, [
    awayHTMLImages,
    awayNewHTMLImages,
    homeHTMLImages,
    homeNewHTMLImages,
    homeWomenHTMLImages,
    thirdHTMLImages,
    thirdNewHTMLImages,
    keeperHTMLImages,
    keeperNewHTMLImages,
    type,
  ]);

  const convertNameToAjaxFont = React.useCallback(
    (array: string[]) => {
      const iconArray = array.map((character) => {
        const matchingImage = images.find((image) => {
          if (!image.src) return false;
          const srcParts = image.src.split('/');
          const nameOfFile = srcParts[srcParts.length - 1].replace('.svg', '');
          // Because an src is a URL, it's encoded so special characters have to be decoded before checking if it matches the character from the input
          const decodedCharacter = decodeURIComponent(nameOfFile);
          if (character === '.') return decodedCharacter === 'dot';
          return decodedCharacter === character;
        });
        return matchingImage as HTMLImageElement;
      });

      return iconArray;
    },
    [images],
  );

  React.useEffect(() => {
    const splittedInput = input.toLowerCase().split('');
    const iconArray = convertNameToAjaxFont(splittedInput);

    setInputArray(splittedInput);
    setOutputArray(iconArray);
  }, [input, convertNameToAjaxFont]);

  return { inputArray, outputArray };
}
