import * as React from 'react';
import type { ShirtType } from '~source/core/models/components/atoms/size';
import useBreakpoints from '~source/ui/hooks/ui/useBreakpoints/useBreakpoints';

type DetailType = {
  phone: number;
  tablet: number;
  desktop: number;
};

type ShirtProperties =
  | 'nameHeight'
  | 'namePosY'
  | 'numberHeight'
  | 'numberPosYRatio'
  | 'specialCharacterRatio'
  | 'paddingNumberRatio'
  | 'paddingCharacterRatio';

type ShirtDetailsType = {
  type: ShirtType | 'default';
  specialCharacterRatio: number;
  paddingCharacterRatio: number;
  paddingNumberRatio: number;
  properties: Record<string, DetailType>;
};

const defaultDetails: ShirtDetailsType = {
  type: 'default',
  specialCharacterRatio: 1.41,
  paddingCharacterRatio: 0.11,
  paddingNumberRatio: 0.18,
  properties: {
    nameHeight: {
      phone: 18,
      tablet: 24,
      desktop: 43,
    },
    namePosY: {
      phone: 125,
      tablet: 85,
      desktop: 160,
    },
    numberHeight: {
      phone: 100,
      tablet: 115,
      desktop: 210,
    },
    numberPosYRatio: {
      phone: 1.7,
      tablet: 1.9,
      desktop: 2.5,
    },
  },
};

const shirtDetails: Partial<ShirtDetailsType>[] = [
  {
    type: '2324_away',
    specialCharacterRatio: 1.2,
  },
  {
    type: '2425_away',
    specialCharacterRatio: 1.18,
    properties: {
      nameHeight: {
        phone: 18,
        tablet: 18,
        desktop: 33,
      },
      namePosY: {
        phone: 50,
        tablet: 50,
        desktop: 95,
      },
      numberHeight: {
        phone: 72,
        tablet: 72,
        desktop: 142,
      },
      numberPosYRatio: {
        phone: 3.8,
        tablet: 3.8,
        desktop: 3.8,
      },
    },
  },
  {
    type: '2425_3rd',
    specialCharacterRatio: 1.18,
    paddingNumberRatio: 0.1,
    properties: {
      nameHeight: {
        phone: 18,
        tablet: 20,
        desktop: 33,
      },
      namePosY: {
        phone: 40,
        tablet: 70,
        desktop: 125,
      },
      numberHeight: {
        phone: 95,
        tablet: 95,
        desktop: 190,
      },
      numberPosYRatio: {
        phone: 2.7,
        tablet: 2.7,
        desktop: 2.9,
      },
    },
  },

  {
    type: '2324_3rd',
    specialCharacterRatio: 1.2,
    properties: {
      namePosY: {
        phone: 118,
        tablet: 92,
        desktop: 175,
      },
      numberPosYRatio: {
        phone: 1.65,
        tablet: 1.8,
        desktop: 2.3,
      },
    },
  },
  {
    type: '2425_keeper',
    specialCharacterRatio: 1.2,
    properties: {
      nameHeight: {
        phone: 18,
        tablet: 20,
        desktop: 36,
      },
      namePosY: {
        phone: 70,
        tablet: 65,
        desktop: 130,
      },
      numberHeight: {
        phone: 90,
        tablet: 90,
        desktop: 160,
      },
      numberPosYRatio: {
        phone: 2.7,
        tablet: 2.7,
        desktop: 2.7,
      },
    },
  },
  {
    type: '2324_keeper',
    specialCharacterRatio: 1.27,
    properties: {
      namePosY: {
        phone: 70,
        tablet: 70,
        desktop: 130,
      },
      numberHeight: {
        phone: 90,
        tablet: 90,
        desktop: 160,
      },
      numberPosYRatio: {
        phone: 2.7,
        tablet: 2.7,
        desktop: 2.7,
      },
    },
  },
  {
    type: '2324_home',
    specialCharacterRatio: 1.22,
  },
  {
    type: '2425_home',
    specialCharacterRatio: 1.18,
    paddingNumberRatio: 0.1,
    properties: {
      nameHeight: {
        phone: 19,
        tablet: 19,
        desktop: 35,
      },
      namePosY: {
        phone: 70,
        tablet: 70,
        desktop: 130,
      },
      numberHeight: {
        phone: 90,
        tablet: 90,
        desktop: 160,
      },
      numberPosYRatio: {
        phone: 2.7,
        tablet: 2.7,
        desktop: 2.7,
      },
    },
  },

  {
    type: '2425_home_vrouwen',
    specialCharacterRatio: 1.24,
    paddingNumberRatio: 0.1,
    properties: {
      nameHeight: {
        phone: 18,
        tablet: 22,
        desktop: 35,
      },
      namePosY: {
        phone: 90,
        tablet: 70,
        desktop: 170,
      },
      numberHeight: {
        phone: 95,
        tablet: 115,
        desktop: 160,
      },
      numberPosYRatio: {
        phone: 2.4,
        tablet: 2.7,
        desktop: 2.4,
      },
    },
  },
];

export default function useShirtCanvasDetails({
  originalHeight,
  shirtType,
}: {
  originalHeight: number;
  shirtType: ShirtType | null;
}) {
  const { isTabletLandscape, isPhone } = useBreakpoints();

  const spaceWidth = React.useMemo(() => {
    if (isPhone) return 20;
    if (isTabletLandscape) return 20;
    return 7;
  }, [isPhone, isTabletLandscape]);

  const getShirtDetail = (prop: ShirtProperties) => {
    if (
      prop === 'specialCharacterRatio' ||
      prop === 'paddingCharacterRatio' ||
      prop === 'paddingNumberRatio'
    ) {
      return (
        shirtDetails.find((shirt) => shirt.type === shirtType)?.[prop] ??
        defaultDetails[prop]
      );
    }

    const value =
      shirtDetails.find((shirt) => shirt.type === shirtType)?.properties?.[
        prop
      ] ?? defaultDetails.properties[prop];

    if (isPhone) {
      if (prop === 'numberPosYRatio') {
        return originalHeight / value.phone;
      }
      return value.phone;
    }
    if (isTabletLandscape) {
      if (prop === 'numberPosYRatio') {
        return originalHeight / value.desktop;
      }
      return value.desktop;
    }
    if (prop === 'numberPosYRatio') {
      return originalHeight / value.tablet;
    }
    return value.tablet;
  };

  return {
    getShirtDetail,
    spaceWidth,
  };
}
